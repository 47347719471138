<template>
  <b-card title=" Responses">
    <div style="display: inline">
      <b-button size="md" variant="secondary" @click="downloadExcel()" v-if="$can('view', 'question_responses')" :disabled="isLoadingTable">
          <feather-icon
            icon="DownloadIcon"
            class="mr-50"
          /><span>{{ (!isLoadingTable) ? 'Export Excel' : 'Loading..' }}</span>
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search float-right">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <vue-good-table
      class="responses-table"
      v-if="rows && !isLoadingTable"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="viewDetails(props.row.details)" v-if="$can('view', 'question_responses')" :disabled="props.row.details === null || props.row.details.length == 0">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>View Response</span>
              </b-dropdown-item>
              <b-dropdown-item @click="viewUsers(props.row.user_id)" v-if="$can('view', 'users')" :disabled="props.row.user_id === null">
                <feather-icon
                  icon="UserIcon"
                  class="mr-50"
                />
                <span>View User</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row.id)" v-if="$can('delete', 'question_responses')">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  <!-- Modals -->
  <b-modal
      id="modal-detail"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDetailModal"
    >
      <template #modal-header="{}">
        <h5>Response</h5>
      </template>

      <b-row>
        <b-col cols="12">
          <div v-if="currentObject.details === null || currentObject.details.length == 0" class="text-center mt-2">
            <div class="demo-spacing-0">
              <b-alert
                variant="warning"
                show
              >
                <div class="alert-body mb-2">
                  No response yet
                </div>
              </b-alert>
            </div>
          </div>
        </b-col>
      </b-row>
      
      <b-row v-if="currentObject.details !== null && currentObject.details.length > 0" >
        <b-col cols="12">
          <div v-for="(item, index) in currentObject.details" :key="index">
            <h5>{{ item.feedback_question_name }}</h5>
            <p>{{ item.answer }}</p>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
      </template>
    </b-modal>


    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ pageName }}</h5>
      </template>

      <p>
        Are you sure you want to delete this response?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import * as XLSX from 'xlsx'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    XLSX
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      table: {
        headers: [],
        rows: []
      },
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Company',
          field: 'user_company',
        },
        {
          label: 'Name',
          field: 'user_name',
        },
        {
          label: 'Email',
          field: 'user_email',
        },
        {
          label: 'Created At',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Created By',
          field: 'created_by.name',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      formState: "View",
      isEdit: false,
      showFormModal: false,
      showDeleteModal: false,
      showDetailModal: false,
      pageName: "Feedback Form Responses",

      currentObject: {
        id: null,
        eventId: null,
        eventBoothId: null,
        details: null
      }
    }
  },
  computed: {
  },
  methods: {
    downloadExcel() {
      this.isLoadingTable = true

      let currentRows = []

      let headers = ["Company", "Name", "Email"]

      let tempIndexById = [];

      this.rows.forEach((item, index) => {
        let currentObject = {}

        currentObject[0] = item.user_company
        currentObject[1] = item.user_name
        currentObject[2] = item.user_email

        if(item.details !== null) {
          item.details.forEach((detail, detailIndex) => {

            var idxTemp = 0

            //search header col index
            tempIndexById.forEach((id,idx) =>{
              if(id == detail.feedback_question_id){
                console.log("idx found for "+id+": "+idx)
                idxTemp = idx + 3
              }
            })

            //if header not found
            if(idxTemp == 0){
              console.log("id not found")
              tempIndexById.push(detail.feedback_question_id)
              headers.push(detail.feedback_question_name)
              console.log(tempIndexById)
              console.log(headers)
              currentObject[tempIndexById.length + 2] = detail.answer
            }else{
              console.log("set to col "+idxTemp )
              currentObject[idxTemp] = detail.answer
            }

            //currentObject[detail.feedback_question_id+2] = detail.answer

            //if(index == 0) {
              //headers.push(detail.feedback_question_name)
            //}
            //headers[detail.feedback_question_id+2] = detail.feedback_question_name
          })
        }

        currentRows.push(currentObject)
      })

      const worksheet = XLSX.utils.json_to_sheet(currentRows)

      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Data")
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

      XLSX.writeFile(workbook, this.pageName + "_" + Date.now() + ".xlsx", { compression: true })

      this.isLoadingTable = false

    },
    viewDetails(details) {
      this.showDetailModal = true
      this.currentObject.details = details

    },
    viewUsers(userId) {
      this.$router.push("/users/" + userId + "/view")
    },
    removeModal(id) {      
      this.showDeleteModal = true

      this.currentObject.id = id
    },
    remove() {
      this.isLoadingDelete = true

      const id = this.currentObject.id

      this.$http.delete('/admin/v1/feedback_responses/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }

          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    fetchData() {
      this.isLoadingTable = true

      this.$http.post('/admin/v1/feedback_responses', {
        event_id: this.eventId,
        event_booth_id: null
      })
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
  },
  created() {
    this.fetchData()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.responses-table .vgt-responsive {
  min-height: 350px;
}
</style>